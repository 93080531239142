import { Fragment } from 'react';
import { Strong } from '@smart-kasa/ui';

import { CardHolder, Currency } from 'components';
import { TransactionType, IPaymentTransaction } from 'types';

import { ReceiptGroup } from './ReceiptGroup';
import { ReceiptRow } from './ReceiptRow';

export interface IReceiptTransactionProps {
  transaction: IPaymentTransaction;
}

const PAYMENT_METHOD: Record<TransactionType, string> = {
  [TransactionType.cash]: 'Готівка',
  [TransactionType.card]: 'Банківська картка',
  [TransactionType.acquiring]: 'Еквайринг',
  [TransactionType.accountTransfer]: 'За реквізитами (IBAN)',
  [TransactionType.cardTransfer]: 'Переказ з картки',
};

export const ReceiptTransaction = ({ transaction }: IReceiptTransactionProps) => (
  <ReceiptGroup>
    {transaction.transactionTypeId === TransactionType.cash ? (
      <Fragment>
        <ReceiptRow
          title={<Strong>Готівка</Strong>}
          value={<Currency value={transaction.amount} />}
        />
        <ReceiptRow title="- Сплачено" value={<Currency value={transaction.depositAmount} />} />
        <ReceiptRow title="- Решта" value={<Currency value={transaction.changeAmount} />} />
      </Fragment>
    ) : (
      <Fragment>
        <ReceiptRow
          title={<Strong>Безготівкова</Strong>}
          value={<Currency value={transaction.amount} />}
        />
        <ReceiptRow title="- Засіб оплати:" value={PAYMENT_METHOD[transaction.transactionTypeId]} />
        <ReceiptRow title="- Валюта операції:" value="Гривня (грн)" />
      </Fragment>
    )}

    <ReceiptRow title="- Платіжна система" value={transaction.paymentNetworkId} />
    <ReceiptRow title="- ЕПЗ" value={transaction.pan && <CardHolder value={transaction.pan} />} />
    <ReceiptRow title="- Банк-еквайр" value={transaction.payload?.bank} />
    <ReceiptRow title="- Код авторизації" value={transaction.approvalCode} />
    <ReceiptRow title="- MID" value={transaction.payload?.merchantId} />
    <ReceiptRow title="- TID" value={transaction.payload?.terminalId} />
    <ReceiptRow title="- RRN" value={transaction.rrn} />
  </ReceiptGroup>
);
