import { TAX_LETTER_MAPPING } from 'contants/taxGroup';
import { Currency } from 'components';

import { ReceiptRow } from './ReceiptRow';

interface ITaxProps {
  letter: string;
  amount: number;
}

export const ReceiptTax = ({ letter, amount }: ITaxProps) => {
  const taxLetter = letter.toUpperCase();
  const taxGroup = TAX_LETTER_MAPPING[taxLetter];

  return <ReceiptRow title={taxGroup} value={<Currency value={amount} kopecks={true} />} />;
};
