import { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { QRCodeSVG } from 'qrcode.react';
import qs from 'query-string';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { Currency } from 'components';
import { IReceipt, IReceiptItem, ReceiptType } from 'types';

import { ReceiptGroup } from './ReceiptGroup';
import { ReceiptRow } from './ReceiptRow';
import { ReceiptItem } from './ReceiptItem';
import styles from './Receipt.module.scss';

const RECEIPT_TYPE_MAPPING: Record<ReceiptType, string> = {
  [ReceiptType.sale]: 'Продаж',
  [ReceiptType.serviceInput]: 'Службове внесення',
  [ReceiptType.serviceOutput]: 'Службова видача',
  [ReceiptType.refund]: 'Повернення',
  [ReceiptType.reversal]: 'Відміна',
  [ReceiptType.offlineStart]: 'Початок офлайн сесії',
  [ReceiptType.offlineFinish]: 'Закінчення офлайн сесії',
};

interface IReceiptProps {
  receipt: IReceipt;
  children?: ReactNode;
}

const TIME_ZONE = process.env.REACT_APP_DEFAULT_TIME_ZONE || 'Europe/Kiev';
const DATE_FORMAT = 'yyyyMMdd';

export const Receipt = ({ receipt, children }: IReceiptProps) => {
  const { formatTime, formatDate } = useIntl();

  const fiscalReceiptUrl = qs.stringifyUrl({
    url: 'https://cabinet.tax.gov.ua/cashregs/check',
    query: {
      id: receipt.fiscalNumber,
      fn: receipt.registrarFiscalNumber,
      date: formatInTimeZone(parseISO(receipt.createdAt), TIME_ZONE, DATE_FORMAT),
      time: formatTime(receipt.createdAt, { hour: '2-digit', minute: '2-digit' }),
      sm: receipt.totalAmount,
      mac: receipt.previousReceiptHash,
    },
  });

  return (
    <Fragment>
      <ReceiptGroup>
        <ReceiptRow title="Тип операції" value={RECEIPT_TYPE_MAPPING[receipt.type]} />
        <ReceiptRow title="Касир" value={receipt.cashierName} />
        <ReceiptRow title="Опис" value={receipt.description} />
        <ReceiptRow title="ФН ПРРО" value={receipt.registrarFiscalNumber} />
        <ReceiptRow title="Чек №" value={receipt.fiscalNumber} />
      </ReceiptGroup>

      {receipt.type === ReceiptType.refund && (
        <ReceiptGroup>
          <ReceiptRow title="Повернення для документа №" value={receipt.relatedFiscalNumber} />
        </ReceiptGroup>
      )}

      {receipt.items && (
        <ReceiptGroup>
          {receipt.items.map((item: IReceiptItem, index: number) => (
            <ReceiptItem item={item} key={index} />
          ))}
        </ReceiptGroup>
      )}

      {receipt.type !== ReceiptType.offlineStart && receipt.type !== ReceiptType.offlineFinish && (
        <Fragment>
          <ReceiptGroup>
            <ReceiptRow
              title="СУМА"
              value={<Currency value={receipt.totalAmount} />}
              className={styles.total}
            />
            <ReceiptRow title="Знижка" value={<Currency value={receipt.discountAmount} />} />
            <ReceiptRow title="Заокруглення" value={<Currency value={receipt.roundingAmount} />} />
          </ReceiptGroup>

          {children}

          <ReceiptRow
            title="Дата та час"
            value={formatDate(receipt.createdAt, { dateStyle: 'short', timeStyle: 'short' })}
          />

          {receipt.previousReceiptHash && (
            <div className={styles.mac}>
              Контрольне число: <pre>{receipt.previousReceiptHash}</pre>
            </div>
          )}

          {receipt.fiscalNumber && receipt.registrarFiscalNumber && (
            <ReceiptGroup className={styles.groupCenter}>
              <QRCodeSVG value={fiscalReceiptUrl} size={150} className={styles.image} />
              <Link
                to={fiscalReceiptUrl}
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Фіскальний чек
              </Link>
            </ReceiptGroup>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
